@media (max-width:769px) {
    .user-l-p{
        padding-left: 10px;
        padding-top: 100px;
        padding-right: 10px;
    }
    .usdt-pl60-h5{
        display: block;
    }
    .usdt-pl60-pc{
        display: none;
    }
    .trade-buyusdt{
        .Loginipt{
            width: 80%;
            padding-right: 20px;
            margin-top: 10px;
        }
    }
    
    .usdtipt{
        z-index: 10;
        .usdt-ipt-tip{
            z-index: 9;
            position: absolute;
            right: 20px;
            bottom: 10px;
            z-index: 20;
          
        }
    }
}
@media (min-width:769px) {
    .user-l-p{
        padding-left: 100px;
        padding-top: 100px;
        padding-right: 100px;
    }
   
    .trade-buyusdt{
        padding-right: 0px !important;
    }
    .usdt-pl60-h5{
        display: none;
    }
    .usdt-pl60-pc{
        display: block;
        
    }
    .trade-buyusdt{
    .Loginipt{
        padding-right: 40px;
        width: 300px;
    }
}
    .usdt-fl{
        .usdt-ipt-tip{
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 20;
        }
    }
}



.trade-buyusdt{
  
   
    .usdt-fc{
        text-align: center;
    }
    .usdt-pl60{
        padding: 30px 0 6px;
    }
    
    .LoginSelect{
        height: 40px;
        .ant-select-selector{
            padding-top: 4px;
            height: 40px;
        }
    }
    .Loginipt{
        height: 40px;
        // width: 300px;
        line-height: 40px;
       
        .ant-input-number-handler-wrap{
            display: none;
        }
        .ant-input{
            height: 40px;
        }
    }
    .usdt-fl{
        float: right;
        display: flex;
        margin-right: 100px;
        .ant-input-group{
            display: flex;
        }
        button{
            margin-left: 20px;
        }
        position: relative;
        .usdt-ipt-tip{
            // position: absolute;
            // right: 10px;
            // top: 10px;
            // z-index: 20;
        }
    }
       
    .trade-tabs{
        font-size: 16px;
        .ant-tabs-nav .ant-tabs-tab-active{
            font-size: 16px;
        }
    }
    b{
        font-size: 22px;
        margin-right: 16px;
    }
    span:nth-of-type(1){
        // color: #9aa5b5;
        font-size: 12px;
    }
}