


.security-usdt{
    .security-usdt-p{
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .fl-r{
        float: right;
    }
}

.set_google{
    .set_google-usdt-p{
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        .googleipt{
            width: 300px;
        }
        .googlebtn{
          
            
        }
    }
    .cr06b{
        color: #06b572;
    }
}