
@media (max-width:769px) {
    .Consultation-p{
        .Consultation-name{
            width: 80%;
            height: 50px;
            margin-bottom: 20px;
         }
         .Consultation-Search{
             .ant-input{
                height: 50px;
             }
             button{
                height: 50px; 
             }
             width: 80%;
         }
    }
    .Consultation-Steps{
        width: 200px;
        height: 300px;
        padding-left: 40px;
        margin: 0 auto;
    }
}
@media (min-width:769px)  {
    .Consultation-p{
        .Consultation-name{
            width: 100px;
            height: 50px;
            border-right:none ;
         }
         .Consultation-Search{
             .ant-input{
                height: 50px;
             }
             button{
                height: 50px; 
             }
            width: 500px;
         }
    }
    .Consultation-Steps{
        width: 600px;
        margin: 0 auto;
    }
}
.pt-80{
    padding-bottom: 80px !important;
}
.tx-r{
    text-align: right;
}
.tx-l{
    text-align: left;
}
.tx-c{
    text-align: center;
}
.bgf4{
    background-color: #f4f8ff !important;
}
.Consultation{

  
    padding-top: 80px;
    // padding-bottom: 50px;
    text-align: center;
    h4{
        height: 80px;
        font-size: 30px;
      }
      p{
        height: 60px;
        font-size: 14px;
      }


.Consultation-liuc{
    span{
        margin-left: 20px;
        margin-right: 20px;
        // color: #;
    }
}

}
