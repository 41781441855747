

.logo {
    width: 120px;
    height: 31px;
    // background: rgba(255, 255, 255, 0.2);
    // margin: 16px 0px 16px 0;
    float: left;
  }
  .site-layout .site-layout-background {
    background: #fff;
  }
  .loginbtn{
    float: right;
    a{
      color: #fff !important;
    } 
  }