@media (max-width:769px) {
    .Login{
        margin: 100px auto;
        width: 100%;
        .Login-card{
            border: none;
        }
    }
}
@media (min-width:769px) {
    .Login{
        margin: 150px auto;
        width:600px;
        min-height: 300px;
        .Login-card{
            padding: 40px;
            padding-top: 20px;
            box-shadow: 0 -1px 7px #cecece;
        }
    }
}

.Login{
    
    h3{
        font-size: 22px;
    }
    .center{
        text-align: center;
    }
    .Loginipt{
        // width: 300px;
        height: 40px;
        .ant-input{
            height: 40px;
        }
    }
    .Loginpsd{
        // width: 300px;
        height: 40px;
        max-height: 40px;
    }
    .Loginbtn{
        height: 40px;
        width: 100%;
    }
    .Login-card{
        width: 100%;
        cursor: pointer;
         .loginGroup{
            .ant-input-group{
                display: flex;
            }
        }
        
    }
    .LoginSelect{
        height: 40px;
        .ant-select-selector{
            padding-top: 4px;
            height: 40px;
        }
    }
    .LoginSearch{
        button{
            width: 112px;
        }
    }
}