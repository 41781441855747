

.user-index-side{
     padding-top:86px ;
     background:#fff;
    .usersetli{
        // padding-left: 24px;
        // padding: 11px 85px 11px 65px!important;
        // font-size: 16px;
        height: auto!important ;
        color:black!important ;
    }
    .usersetli-85{
        // padding-left:85px;
        padding: 11px 85px 11px 65px!important;
        height: auto!important ;
        color:black!important ;
    }


    .userset-sbli{
        // font-size: 16px;
        // padding-left:40px;
        padding-top: 11px!important;
        padding-bottom: 11px!important;
        color:black;
        .ant-menu-item{
            
            padding: 0;
            color:black !important;;
            a{
                color:#1890ff !important;;
            }
        }
    }

// .ant-menu-dark .ant-menu-submenu-selected{
//    a{
  
//    }
// }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background-color: #e6f7ff;
        span{
            color:black !important;;
        }
    }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub{
        background: #fff !important;;
        color:black!important;
    
    }
    .ant-menu-dark .ant-menu-submenu-open{
        color:black!important;
    
    }
    .ant-menu-dark  .ant-menu-submenu-active{
        .ant-menu-submenu-title{
            color:black!important;
        }
        .anticon-mail{
            color:black!important;
            
        }
    }
    .user-collapsed{
        padding: 10px;
        button{
            width: 100%;
            height: 40px;
        }
    }
}
// .ant-menu-submenu{
//     .ant-menu{
//         background: rgb(244, 248, 255);
//         .ant-menu-item{
//             a{
//                 color: black;
//             }
//             font{
//                 color: black;
//             }
           
//         }
//         .ant-menu-item-only-child{
//             color:black;
//         }
//         .ant-menu-item-selected > a{
//             color:white ;
//         }
       
//         .ant-menu-item-selected{
//             a{
//                 color:white;
//             }
//         }
//     }
// }