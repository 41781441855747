@media (max-width:769px) {
  .banner-one-pc{
    display: none !important;
  }
  .banner-one-h5{
    display: block ;
  }
  .ant-carousel .slick-slide h3 {
    font-size: 22px;
    font-size: 28px;
    padding: 0px 40px 0px 40px;
    line-height: 60px;
  }
    .ant-carousel .slick-slide h4{
        color: #fff;
        height: 60px;
        
        font-size: 16px;
      }
    .ant-carousel .slick-slide {
        height: 360px;
    }
    .cooperation{
        .ant-col{
            width: 100%;
        }   
    }
    .row-connect{
        .ant-col{
          width: 100%;
            padding-right: 0 !important;  
            padding-left: 0px;
          }
    }
    .otcc-i-fus3-r{
        text-align: center;
        width: 100%;
        .ant-col{
          padding-right: 0 !important;  
        }
        img{
            width: 100%;
            margin-bottom: 20px;

        }
       
    }
    .Consultation-p{
        .Consultation-name{
            width: 80%;
            height: 50px;
            margin-bottom: 20px;
         }
         .Consultation-Search{
             .ant-input{
                height: 50px;
             }
             button{
                height: 50px; 
             }
             width: 80%;
         }
    }
}
@media (min-width:769px)  {
  .banner-one-pc{
    display: block;
  }
  .banner-one-h5{
    display: none !important;
  }
  .ant-carousel .slick-slide h3 {
    font-size: 34px;
    line-height: 80px;
  }
    .ant-carousel .slick-slide h4{
        color: #fff;
        height: 120px;
        font-size: 18px;
      }
    .ant-carousel .slick-slide {
        height: 600px;
    }
    .row-connect{
        width: 80%;
        margin: 0 auto;
        .ant-col{
         
            padding-left: 20px;
          }
    }
    .Different{
        padding-left: 100px;
        padding-right: 100px;
    }
    .otcc-i-fus3-r{
        .ant-col{
            padding-left:20px ;  
          }
        img{
            width: 158px;
        }
    }
    .Consultation-p{
        .Consultation-name{
            width: 100px;
            height: 50px;
            border-right:none ;
         }
         .Consultation-Search{
             .ant-input{
                height: 50px;
             }
             button{
                height: 50px; 
             }
            width: 500px;
         }
    }
}
.pt-80{
    padding-bottom: 80px !important;
}
.tx-r{
    text-align: right;
}
.tx-l{
    text-align: left;
}
.tx-c{
    text-align: center;
}
.bgf4{
    background-color: #f4f8ff;
}
.banner-page{
    .ant-carousel .slick-slide {
        background: #364d79;
      }
   
}
.ant-carousel .slick-slide {
    text-align: center;
  
    line-height: 160px;
   
    overflow: hidden;
  }
  .banner-bannerimg{
   
    // height: 400px;
  }
  .banner-bannerimg .slick-slide:nth-of-type(1){
    background: url('../static/12.jpg');
    background-position: 50%;
    background-size: cover;
  
  }
  .banner-bannerimg .slick-slide:nth-of-type(2){
   
   
    background: url('../static/12.jpg');
    background-position: 50%;
    background-size: cover;
  }
  .banner-one{
    margin: 8% auto;
    .banner-one-left{
      // position: absolute;
      bottom: 0;
      text-align: left !important;
      .c2c-title{
        font-size: 40px;
        margin-top: 60px;
      }
      .c2c-text{
        font-size: 24px;
        font-weight: lighter
      }
     
      p{
        color: white;
        height: 35px;
        button{
          width: 100px;
          background: none;
          color:#1890ff;
          box-shadow: 0 2px 0 rgba(0,0,0,.015);
          cursor: pointer;
          border-radius: 5px;
          // border-color: blue
        }
      }
      
    }
    .banner-one-card{
      width: 280px;;
      margin-bottom: 8px;
      background-color: #1C2A3F;
      border-radius: 2px;
      background-size: cover;
      color: white;
      display: block;
      // padding: 16px 100px 16px 20px;
      position: relative;
      text-decoration: none;
      .ant-card-body{
        text-align: left;
        padding: 10px;
      }
      .rno-2-hero-nav-item-category{
        box-sizing: border-box;
        max-width: 62px;
        border: 1px solid #04C8DC;
        padding: 0 6px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #04C8DC;
      }
      .rno-2-hero-nav-a{
        font-size: 12px;
        color: #04C8DC;
      }
    }
  }
  .ant-carousel .slick-slide h3 {
    color: #fff;
    height: 80px;
    // font-size: 34px;
    
  }
  .ant-carousel .slick-slide h4{
    // color: #fff;
    // height: 120px;
    // font-size: 20px;
  }
  .common-connect{
    padding-top: 80px;
    padding-bottom: 50px;
    text-align: center;
      h4{
        height: 80px;
        font-size: 30px;
      }
      p{
        height: 80px;
        font-size: 14px;
        padding: 10px;
      }
      .row-connect-div{
        h5{
          font-size: 14px;
        }
        div{
          font-size: 14px;
        }
      }
  }

  .connect{
      .col-img{
          
      }
      .col-txt{
          padding-left: 40px;
      }
      .row-connect{
        //   width: 80%;
        //   margin: 0 auto;
      }
      .row-connect-div{
          margin-bottom: 60px;
          h5{
              font-size: 18px;
          }
          div{
            font-size: 14px;
        }
        img{
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
      }
  }

  .Different{
     padding-bottom: 80px;
       margin: 0 auto;
     
      img{
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    p{
        margin-bottom: 40px;
    }
    .card-div{
        min-height: 290px;
    }
  }

  .cooperation{
    padding-bottom: 80px;
    .otcc-i-fus3-r{
        display: flex;
        justify-content: center;
        img{
            height: 76px;
            object-fit: contain;
        }
      }
  }
 .Consultation-p{
    //  .Consultation-name{
    //     width: 100px;
    //     height: 50px;
    //     border-right:none ;
    //  }
    //  .Consultation-Search{
    //      .ant-input{
    //         height: 50px;
    //      }
    //      button{
    //         height: 50px; 
    //      }
    //     width: 500px;
       
    //  }
 } 
.Consultation-liuc{
    span{
        margin-left: 20px;
        margin-right: 20px;
        // color: #;
    }
}