
@media (max-width:769px) {
    .Mas-page{
        .mas-banner-h3{
            margin-top: 40px;
            height: 30px !important;
        }
        .Mas-banner{
            button:nth-of-type(1){
                margin-right: 20px;
            }
        }
    }
}
@media (min-width:769px) {
    .Mas-page{
        .mas-banner-h3{
            height: 80px;
        }
        .Mas-banner{
            button:nth-of-type(1){
                margin-right: 100px;
            }
        }
        .SceneDemonstration{
            .ant-tabs-tab:nth-of-type(1){
                margin-right: 400px;
            }
        }
    }
}
@media (max-width:1024px) {
    .SceneDemonstration{
        img{
            width: 100%;
            height: 100%;
            padding: 10px;
            object-fit: contain;
        }
    }
}
@media (min-width:1024px) {
    .SceneDemonstration{
        img{
            width: 1000px;
            height: 400px;
            object-fit: contain;
        }
    }
}

.Mas-page{
    
    .ant-carousel .slick-slide {
        background: url('../static/yueqiu.png');
        background-position: 50%;
        background-size: cover;
      }
    .Consultation{
        background: white !important;
    }
    .SceneDemonstration{
        .ant-tabs-nav .ant-tabs-tab{
            font-size: 18px;
        }
    }
}