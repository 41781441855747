
@media (max-width:769px) {
    .Withdraw-usdt{
        .h40-mb{
            .ant-input{
                width: 100%;
            }
        }
    }
}
@media (min-width:769px) {
    .Withdraw-usdt{
        .h40-mb{
            .ant-input{
                width: 80%;
            }
        }
    }
}
.Withdraw-usdt{
    b{
        font-size: 22px;
    }
    .fz16{
        font-size: 16px;
    }
    .usdt-pl60{
        padding: 30px 0 6px;
    }
    .h40-mb{
        .ant-input-group-addon{
            width: 120px;
        }
        margin-bottom: 18px;
        height: 40px;
        .ant-input{
            height: 40px;
            // width: 80%;
        }
        
    }
    .btn{
        width: 80%;
        text-align: center;
    }
    .te-c{
        padding-left: 15px;
    }
    
    .Loginipt{
        // width: 300px;
        height: 40px;
        .ant-input{
            height: 40px;
        }
    }
    .LoginSearch{
        .ant-input-group-addon{
            background: red;
            width: 112px !important;
            button{
                width:112px !important;
            }
        }
        // button{
        //     width: 112px !important;
        // }
        // .ant-btn{
        //     width: 112px !important;
        // }
    }
}